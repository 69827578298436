<template>
  <v-row align="center" justify="center" class="text-center">
    <v-btn class="mb-4"
      ><download-excel
        class="btn btn-default"
        :data="data"
        :fields="json_fields"
        worksheet="My Worksheet"
        name="ผลรางวัลลอตเตอรี่ลาว รางวัลที่ 1.xls"
        :header="'ผลรางวัลลอตเตอรี่ลาว รางวัลที่ 1'"
      >
        <img src="@/assets/Excel-Logo.png" width="50px" />
        ผลรางวัลลอตเตอรี่ลาว รางวัลที่ 1
      </download-excel></v-btn
    >
  </v-row>
</template>
<script>
import moment from "moment";
export default {
  // :formDate="datefrom"
  // :toDate="dateto"
  props: ["data"],
  data() {
    return {
      json_fields: {
        หมายเลขออเดอร์: {
          field: "transactionNo",
          callback: (value) => {
            return "'" + value.toString();
          },
        },
        วันที่สั่ง: {
          field: "order.createdAt",
          callback: (value) => {
            return moment(value).format("MM/DD/YYYY HH:mm");
          },
        },
        'ชื่อ-นามสกุลผู้สั่ง': {
          field: "user",
          callback: (value) => {
            return `${value.firstName}  ${value.lastName}`;
          },
        },
        หมายเลขโทรศัพท์: {
          field: "user.telNumber",
          callback: (value) => {
            return "'" + value.toString();
          },
        },
        ชื่อธนาคาร: "user.bankName",
        เลขที่บัญชี: {
          field: "user.bankNo",
          callback: (value) => {
            return "'" + value.toString();
          },
        },
        อีเมล: "user.email",
        หมายเลข: "number",
        // พาวเวอร์บอล: "powerball",
        ราคา: "price",
        รางวัล: {
          field: "price",
          callback: (value) => {
            return value*100000;
          },
        },
        สถานะ: "status",
      },

      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      // { text: "ลำดับ", value: "count", align: "center" },
      //   { text: "หมายเลขออเดอร์", value: "transactionNo", align: "center" },
      //   { text: "หมายเลข", value: "number", align: "center" },
      //   { text: "พาวเวอร์บอล", value: "powerball", align: "center" },
      //   { text: "powerPlay", value: "powerPlay", align: "center" },
      //   { text: "ข้อมูล", value: "action", align: "center" },
      list1: [],
    };
  },
  async created() {
    // this.getREportXray();
  },
  methods: {
    // async getREportXray() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/form/xrayReport`
    //   );
    //   console.log("data", response.data.data[0]);
    //   this.list1 = response.data.data[0];
    // },
  },
};
</script>
