<template>
  <div>
    <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
      <loading :active.sync="loading"></loading>
      <v-card outlined class="py-4">
        <v-row justify="center" class="ma-4">
          <v-col cols="12"><h1>ตรวจรางวัลลอตเตอรี่ลาว</h1></v-col>
          <v-col cols="12" md="3">กรอกเลขที่ออก:</v-col>
          <v-col cols="12" md="7"
            ><v-text-field
              v-model="form.number"
              hint="ตัวอย่าง 123456"
              outlined
              dense
            ></v-text-field
          ></v-col>
          <!-- <v-col cols="12" md="3">กรอกเลข Powerball:</v-col>
          <v-col cols="12" md="7"
            ><v-text-field
              v-model="form.powerballNumber"
              hint="ตัวอย่าง 10"
              outlined
              dense
            ></v-text-field
          ></v-col> -->
          <v-col cols="12" md="3">งวดที่ออก:</v-col>
          <v-col cols="12" md="7">
            <v-text-field
              v-model="form.rewardDate"
              placeholder="2022-01-31"
              hint="ตัวอย่าง 2022-01-31"
              outlined
              dense
            ></v-text-field>
            <!-- <v-menu
              v-model="menurewardDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.rewardDate"
                  label=""
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.rewardDate"
                @input="menurewardDate = false"
                no-title
                scrollable
              ></v-date-picker>
            </v-menu> -->
          </v-col>
        </v-row>
        <v-row class="ma-4">
          <v-btn>กลับ</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="submit()" color="primary">ตรวจรางวัล</v-btn>
        </v-row>
      </v-card>
    </v-form>
    <v-card outlined class="mt-10">
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 1</h3>
        </v-col>
        <v-col><h3 style="font-weight: 800">ถูกรางวัล 6 ตัว</h3></v-col>
        <v-col><ExportLotteryLao1 :data="items1" /></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-data-table :headers="headers" :items="items1">
              <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center">
                  <v-icon @click="viewOrder(item)">mdi-eye</v-icon>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card outlined class="mt-10">
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 2</h3>
        </v-col>
        <v-col><h3 style="font-weight: 800">ถูกรางวัล 5 ตัว</h3></v-col>
        <v-col
          ><v-col><ExportLotteryLao2 :data="items2" /></v-col
        ></v-col> </v-row
      ><v-row dense>
        <v-col cols="12">
          <v-card>
            <v-data-table :headers="headers" :items="items2">
              <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center">
                  <v-icon @click="viewOrder(item)">mdi-eye</v-icon>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card outlined class="mt-10">
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 3</h3>
        </v-col>
        <v-col><h3 style="font-weight: 800">ถูกรางวัล 4 ตัว</h3></v-col>
        <v-col
          ><v-col><ExportLotteryLao3 :data="items3" /></v-col
        ></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-data-table :headers="headers" :items="items3">
              <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center">
                  <v-icon @click="viewOrder(item)">mdi-eye</v-icon>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card outlined class="mt-10">
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 4</h3>
        </v-col>
        <v-col><h3 style="font-weight: 800">ถูกรางวัล 3 ตัว</h3></v-col>
        <v-col
          ><v-col><ExportLotteryLao4 :data="items4" /></v-col
        ></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-data-table :headers="headers" :items="items4">
              <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center">
                  <v-icon @click="viewOrder(item)">mdi-eye</v-icon>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card outlined class="mt-10">
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 5</h3>
        </v-col>
        <v-col><h3 style="font-weight: 800">ถูกรางวัล 2 ตัว</h3></v-col>
        <v-col
          ><v-col><ExportLotteryLao5 :data="items5" /></v-col
        ></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-data-table :headers="headers" :items="items5">
              <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center">
                  <v-icon @click="viewOrder(item)">mdi-eye</v-icon>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { Decode, Encode } from "@/services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ExportLotteryLao1 from "@/components/ExportExcel/ExportLotteryLao1";
import ExportLotteryLao2 from "@/components/ExportExcel/ExportLotteryLao2";
import ExportLotteryLao3 from "@/components/ExportExcel/ExportLotteryLao3";
import ExportLotteryLao4 from "@/components/ExportExcel/ExportLotteryLao4";
import ExportLotteryLao5 from "@/components/ExportExcel/ExportLotteryLao5";

export default {
  components: {
    Loading,
    ExportLotteryLao1,
    ExportLotteryLao2,
    ExportLotteryLao3,
    ExportLotteryLao4,
    ExportLotteryLao5,
  },
  data() {
    return {
      headers: [
        { text: "ลำดับ", value: "count", align: "center" },
        {
          text: "หมายเลขออเดอร์",
          value: "order.transactionNo",
          align: "center",
        },
        { text: "ชื่อ", value: "user.firstName", align: "center" },
        { text: "นามสกุล", value: "user.lastName", align: "center" },
        { text: "หมายเลขโทรศัพท์", value: "user.telNumber", align: "center" },
        { text: "หมายเลข", value: "number", align: "center" },
        // { text: "พาวเวอร์บอล", value: "powerball", align: "center" },
        { text: "ราคา", value: "order.totalPrice", align: "center" },
        // { text: "powerPlay", value: "powerPlay", align: "center" },
        { text: "ข้อมูล", value: "action", align: "center" },

        // transactionId: 1143
        // transactionNo: "20220131001143"
      ],
      items1: [],
      items2: [],
      items3: [],
      items4: [],
      items5: [],
      // items6: [],
      // items7: [],
      // items8: [],
      // items9: [],
      //   menurewardDate: false,
      //   datefrom: new Date().toISOString().substr(0, 10),
      //   dateto: new Date().toISOString().substr(0, 10),
      //   menufrom: false,
      //   menuto: false,
      loading: false,
      checkValidate: true,
      lazy: false,
      form: {
        number: "",
        // powerballNumber: "",
        rewardDate: "",
        // number: "29,20,30,31,33",
        // powerballNumber: "10",
        // rewardDate: "2022-01-31",
      },
    };
  },
  created() {},
  methods: {
    viewOrder(val) {
      console.log("id", val);
      localStorage.setItem("orderdata", Encode.encode(val));
      window.open("/EditLaoOrder");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        this.loading = true;
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("DDLotAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        // https://readylotservice.readylottery.com/transaction/checkRewardLao?rewardVal=29,20,30,31,33&rewardDate=2022-01-31&powerBall=10
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/transaction/checkRewardLao?rewardVal=${this.form.number}&rewardDate=${this.form.rewardDate}`,
          auth
        );
        // /transaction/checkRewardLao?rewardVal=449945&rewardDate=2022-02-07
        console.table("response.data", response.data.data);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `ตรวจรางวัลสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });

          let items1 = response.data.data.correct6;
          let items2 = response.data.data.correct5;
          let items3 = response.data.data.correct4;
          let items4 = response.data.data.correct3;
          let items5 = response.data.data.correct2;
          // let items6 = response.data.data.correct3;
          // let items7 = response.data.data.correct2_power;
          // let items8 = response.data.data.correct1_power;
          // let items9 = response.data.data.correct_power;

          // number: Array(6)
          // 0: 7
          // 1: 0
          // 2: 6
          // 3: 0
          // 4: 5
          // 5: 6
          // length: 6
          // [[Prototype]]: Array(0)
          // order:
          // adminName: "ไอซ์ "
          // agentId: null
          // buyToFlag: false
          // buyType: "6"
          // createdAt: "2022-01-24T05:53:08.000Z"
          // drawDate: ""
          // globalId: null
          // id: 476
          // paymentType: "PAYSOLUTION"
          // paysolutionId: "3073007"
          // receiveFirstName: ""
          // receiveFromFirstName: null
          // receiveFromLastName: null
          // receiveFromTel: null
          // receiveLastName: ""
          // receiveTel: ""
          // remark: null
          // reserveByName: null
          // reserveByPhone: null
          // scanedTicket: "https://readylotservice.readylottery.com/lao-images/imgs-jPgPF.jpg"
          // selectedNumber: "[{\"set\":[\"5\",\"4\",\"6\",\"0\",\"9\",\"3\"],\"price\":20},{\"set\":[6,3,2,7,6,2],\"price\":20},{\"set\":[9,4,0,2,2,9],\"price\":20},{\"set\":[7,0,6,0,5,6],\"price\":20},{\"set\":[6,8,9,6,4,9],\"price\":20}]"
          // slipImgUrl: null
          // status: "สั่งซื้อสำเร็จ"
          // totalPrice: 100
          // transactionNo: "L20220124000476"
          // updatedAt: "2022-01-24T06:03:44.000Z"
          // user: {id: 1522, password: null, username: null, email: 'Phuphaja2550@gmail.com', firstName: 'ปิยะมาศ', …}
          // userId: 1522

          this.items1 = items1.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.order.id === value.order.id)
          );
          this.items2 = items2.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.order.id === value.order.id)
          );
          this.items3 = items3.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.order.id === value.order.id)
          );
          this.items4 = items4.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.order.id === value.order.id)
          );
          this.items5 = items5.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.order.id === value.order.id)
          );
          // this.items6 = items6.filter(
          //   (value, index, self) =>
          //     index ===
          //     self.findIndex((t) => t.transactionId === value.transactionId)
          // );
          // this.items7 = items7.filter(
          //   (value, index, self) =>
          //     index ===
          //     self.findIndex((t) => t.transactionId === value.transactionId)
          // );
          // this.items8 = items8.filter(
          //   (value, index, self) =>
          //     index ===
          //     self.findIndex((t) => t.transactionId === value.transactionId)
          // );
          // this.items9 = items9.filter(
          //   (value, index, self) =>
          //     index ===
          //     self.findIndex((t) => t.transactionId === value.transactionId)
          // );
          //   console.log("answer", answer);
          //   transactionId

          for (let i in this.items1) {
            this.items1[i].count = parseInt(i) + 1;
          }
          for (let i in this.items2) {
            this.items2[i].count = parseInt(i) + 1;
          }
          for (let i in this.items3) {
            this.items3[i].count = parseInt(i) + 1;
          }
          for (let i in this.items4) {
            this.items4[i].count = parseInt(i) + 1;
          }
          for (let i in this.items5) {
            this.items5[i].count = parseInt(i) + 1;
          }
          // for (let i in this.items6) {
          //   this.items6[i].count = parseInt(i) + 1;
          // }
          // for (let i in this.items7) {
          //   this.items7[i].count = parseInt(i) + 1;
          // }
          // for (let i in this.items8) {
          //   this.items8[i].count = parseInt(i) + 1;
          // }
          // for (let i in this.items9) {
          //   this.items9[i].count = parseInt(i) + 1;
          // }

          this.loading = false;
        } else {
          this.$swal.fire({
            icon: "error",
            text: `ตรวจรางวัลไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.loading = false;
        }
      }
    },
  },
};
</script>
